import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Settings from '../Settings';

export default function MainPage() {
    return (
        <Box 
            pt={{ xs: 5, sm: 10 }}
            pb={{ xs: 5, sm: 5 }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <center>
                        <Typography variant='h5'>{ Settings.menu[0].name }</Typography>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Button component={RouterLink} to={ Settings.menu[1].url } variant="contained" sx={{ width: '100%' }}>{ Settings.menu[1].button }</Button>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Button component={RouterLink} to={ Settings.menu[2].url } variant="contained" sx={{ width: '100%' }}>{ Settings.menu[2].button }</Button>
                    </center>
                </Grid>

                <Grid item xs>
                    <center>
                        <Button component={RouterLink} to={ Settings.menu[3].url } variant="contained" sx={{ width: '100%' }}>{ Settings.menu[3].button }</Button>
                    </center>
                </Grid>

                <Grid item xs>
                    <center>
                        <Button component={RouterLink} to={ Settings.menu[4].url } variant="contained" sx={{ width: '100%' }}>{ Settings.menu[4].button }</Button>
                    </center>
                </Grid>
            </Grid>
        </Box>
    )
}