import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { observer } from "mobx-react-lite";
import calcState from "../store/calcState";

import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Settings from '../Settings';
import InfoLoadComponent from '../Components/InfoLoadComponent';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import Rating from '@mui/material/Rating';
import { LoadingButton } from '@mui/lab';
import { Label } from '@mui/icons-material';
import { Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar } from '@mui/material';

// Инициализация массива duration во время получения url axios


const CalculatePage = observer(() => {
    // Стейты для переключения категории
    const [changeType, setChangeType] = useState('1');
    const handleChangeType = (newValue: string) => {
        setChangeType(newValue);
    };

    // Стейт для получения вводимой информации


    const [game, setGame] = useState('');    
    const [snackbar, setSnackbar] = useState(false);    
    const [store] = useState(calcState);

    function handleSnackbar() {
        setSnackbar(!snackbar);
    }

    function handleDurationChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, lesson_id: number, id: number) {
        
        let value: number;
        
        if (event.target.value.length === 0) {
            value = 0;
        } else {
            value = parseInt(event.target.value);
        }

        if (value >= 0) {
            store.setField(id, value);
        }
    }

    function setRate(newValue: number | null) {
        store.setRate(newValue);
    }    

    function saveRate() {
        store.saveRate();
        let exerciseArr: any[] = store.selectedExercises;
        exerciseArr.forEach(el => {        
            let typeIndex: number = state.types.findIndex(elem => elem.id === el.type_id);
            let lessIndex: number = state.exercises.findIndex(elem => elem.id === el.lesson_id);
            let exerciseIndex: number = state.exercises[lessIndex].lessons.findIndex(elem => elem.id === el.id);
            el.type_name = state.types[typeIndex].name;
            el.lesson_name = state.exercises[lessIndex].name;
            el.exercise_name = state.exercises[lessIndex].lessons[exerciseIndex].name;
        });
        axios.post('https://warmten.ru/api/v1/list/setRating.php', {
            game: game,
            rate: store.rating,
            exercises: exerciseArr
          })
          .then(function (response) {
              store.saveRate();
              setGame('');
              setRate(0);
              handleSnackbar();
          })
          .catch(function (error) {
          });
    }
    
    // Стейт для получения инфы axios
    const [state, setState] = useState<AppState>({
        exercises: [],
        types: [],
        games: [],
        isLoadingTypes: true,
        isLoadingCalc: true,
    });

    useEffect(() => {
        let coutType = 0;
        // Получаем типы
        axios.get(`https://warmten.ru/api/v1/list/getTypes.php`)
        .then(res => {
            setState((oldState) => ({ ...oldState, types: res.data}));
            coutType = res.data.length;
        })
        .catch(err => {
        })
        .finally(() => {
            setState((oldState) => ({ ...oldState, isLoadingTypes: false}));
        });

        axios.get(`https://warmten.ru/api/v1/list/getGames.php`)
        .then(res => {
            setState((oldState) => ({ ...oldState, games: res.data}));
        })
        .catch(err => {
        })
        .finally(() => {
        });

        // Получаем данные для калькулятора
        axios.get(`https://warmten.ru/api/v1/list/getLessons.php`)
        .then(res => {
            let obj: any[] = res.data;
            axios.get(`https://warmten.ru/api/v1/list/getExercises.php`)
            .then(resp => {
                obj.forEach(firstObj => {
                    const matchingObjs = resp.data.filter((secondObj: any) => secondObj.lesson_id === firstObj.id);
                    firstObj.lessons = matchingObjs;
                  });
                  
                  setState((oldState) => ({ ...oldState, exercises: obj}));
                  interface Field {
                    id: number;
                    lesson_id: number;
                    type_id: number;
                    value: number;
                }
                interface Exercises {
                    id: number;
                    checked: boolean; 
                }
                let fields: Field[] = [];
                let exercises: Exercises[] = [];
                obj.forEach((el: any) => {
                    exercises.push({id: el.id, checked: false});
                    
                    if (el.lessons.length > 0) {
                        const idType: number = el.type_id;
                        for (let i = 0; i < el.lessons.length; i++) {
                            const l = el.lessons[i];                            
                            fields.push({ id: l.id, 
                                lesson_id: l.lesson_id, 
                                type_id: idType,
                                value: 0 });
                        }
                    }
                });
                
                store.fields = fields;
                
                store.exercises = exercises;
            })
            .catch(err => {
            })
            .finally(() => {
            });            
        })
        .catch(err => {
        })
        .finally(() => {
            setState((oldState) => ({ ...oldState, isLoadingCalc: false}));
        });        
    }, []);

    return (
        <Box 
            pt={{ xs: 5, sm: 3 }}
            pb={{ xs: 5, sm: 3 }}
        >
            { (state.isLoadingTypes && state.isLoadingCalc) ? (
                <>
                    <InfoLoadComponent/>
                </>
            ) : (

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <center>
                        <Typography variant='h5'>{ Settings.menu[1].name }</Typography>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Typography variant='body1'>{ '1. Выберите необходимые упражнения' }</Typography>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <TabContext value={changeType}>

                        <TabList 
                            scrollButtons
                            allowScrollButtonsMobile
                            onChange={
                                (event, newValue) => {
                                    handleChangeType(newValue)
                                }
                            } 
                            aria-label="lab API tabs example" 
                            centered
                        >
                            {
                                state.types.map(
                                    (type) => {
                                        return(
                                            <Tab key={`type-t-${type.id}`} label={ type.name } value={`${type.id}`} />
                                        )
                                    }
                                )
                            }
                        </TabList>

                        {
                            state.types.map(
                                (type) => {
                                    return(
                                        <TabPanel key={`type-v-${type.id}`} value={`${type.id}`} sx={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ width: '100%' }} aria-label="collapsible table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                <Typography variant='body1'>{`${type.name} упражнения`}</Typography>
                                                            </TableCell>
                                                            <TableCell/>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {
                                                        state.exercises
                                                        .filter((exercise) => exercise.type_id === type.id)
                                                        .map((exercise) => {    
                                                                                                                    
                                                            if (exercise.lessons.length > 0) {
                                                                
                                                                let index: number = store.exercises.findIndex((el) => el.id === exercise.id);
                                                                return (
                                                                    <>
                                                                    <TableRow key={`row-t-${exercise.id}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                                        <TableCell component="th" scope="row">
                                                                            <Typography sx={{ color: Settings.Colors.Button.Primary }}>
                                                                            <FormControlLabel control={
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    checked={ store.exercises[index].checked }
                                                                                    onClick={() => {store.exercises[index].checked = !store.exercises[index].checked}}                          
                                                                                />
                                                                                } label={exercise.name + ` (${exercise.lessons.length})`} />
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                        <Collapse in={ store.exercises[index].checked } timeout="auto" unmountOnExit>
                                                                            <Box sx={{ margin: 0, width:'100%' }}>
                                                                            <Table size="small" aria-label="purchases">
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell>
                                                                                            <Typography sx={{ color: Settings.Colors.Button.Primary }}>
                                                                                                Тип упражнения
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell align="right">
                                                                                            <Typography sx={{ color: Settings.Colors.Button.Primary }}>
                                                                                                Секунды
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                {
                                                                                    exercise.lessons.map((lesson) => {        
                                                                                        let index: number = store.fields.findIndex((el) => el.id === lesson.id);                            
                                                                                        return(
                                                                                            <TableRow key={`${lesson.id}-${lesson.name}`}>
                                                                                                <TableCell component="th" scope="row">
                                                                                                    {lesson.name}
                                                                                                </TableCell>
                                                                                                
                                                                                                <TableCell align="right">
                                                                                                    <Tooltip title="В секундах" disableInteractive>
                                                                                                        <TextField
                                                                                                            id={`duration-number${lesson.id}`}
                                                                                                            sx={ { width: '95px', textAlign: 'center'} }                      
                                                                                                            onChange={ 
                                                                                                                (event) => {
                                                                                                                    handleDurationChange(event, exercise.type_id, lesson.id);
                                                                                                                }
                                                                                                            }
                                                                                                            variant="standard"
                                                                                                            value={store.fields[index].value}                                                                                                                                 
                                                                                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', sx: { textAlign: 'center'}  }}
                                                                                                        />
                                                                                                    </Tooltip>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                </TableBody>
                                                                            </Table>
                                                                            </Box>
                                                                        </Collapse>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    </>
                                                                );
                                                            } else {
                                                                return(
                                                                    <></>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </TabPanel>
                                    )
                                }
                            )
                                
                        }
                            
                    </TabContext>
                </Grid>

                <Grid item xs={12}>
                    <br/>
                    <center>
                        <Typography variant='h5'>Результат вычислений</Typography>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <Tooltip title="Общее количество упражнений" disableInteractive>
                        <TextField
                            // disabled
                            id="outlined-disabled"
                            InputProps={{ readOnly: true }}
                            label="Всего упражнений"
                            value={ store.exercisesCount }
                            sx={{ width: '100%' }}
                        />
                    </Tooltip>
                    <br/><br/>
                    <Tooltip title="Общая длительность всех упражнений" disableInteractive>
                        <TextField
                            // disabled
                            id="outlined-disabled"
                            InputProps={{ readOnly: true }}
                            label="Общая длительность"
                            value={ store.sum }
                            sx={{ width: '100%' }}
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='button'>Количество</Typography>
                    <br/><br/>
                    <TextField
                        // disabled
                        id="outlined-disabled"
                        InputProps={{ readOnly: true }}
                        label="Цифровые"
                        //defaultValue={ MathUtils.getPersent(true, 0, 0) }
                        value={ store.exercisesType1 }
                        sx={{ width: '100%' }}
                    />
                    <br/><br/>
                    <TextField
                        // disabled
                        id="outlined-disabled"
                        InputProps={{ readOnly: true }}
                        label="Физические"
                        //defaultValue={ MathUtils.getPersent(false, 0, 0) }
                        value={ store.exercisesType2 }
                        sx={{ width: '100%' }}
                        
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='button'>Секунды</Typography>
                    <br/><br/>
                    <TextField
                        // disabled
                        id="outlined-disabled"
                        InputProps={{ readOnly: true, endAdornment: <InputAdornment position="start">сек.</InputAdornment> }}
                        label="Цифровые"
                        //defaultValue={ MathUtils.getPersent(true, 0, 0) }
                        value={ store.sumType1 }
                        sx={{ width: '100%' }}
                    />
                    <br/><br/>
                    <TextField
                        // disabled
                        id="outlined-disabled"
                        InputProps={{ readOnly: true, endAdornment: <InputAdornment position="start">сек.</InputAdornment> }}
                        label="Физические"
                        //defaultValue={ MathUtils.getPersent(false, 0, 0) }
                        value={ store.sumType2 }
                        sx={{ width: '100%' }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography component="legend">Оценить мою разминку</Typography>
                    <Rating
                        name="size-large"
                        size="large"
                        max={10}
                        value={ store.rating }
                        onChange={(event, newValue) => { setRate(newValue) }}
                      />
                </Grid>

                <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Выберите игру</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={game}
                    label="Выберите игру"
                    onChange={(event) => setGame(event.target.value as string)}
                  >
                    {
                        state.games.map((el) => {
                            return(
                            <MenuItem key={el.id} value={el.name}>{el.name}</MenuItem>
                            )
                        })
                    }
                  </Select>
                </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                    <div>
                        <LoadingButton loading={ store.status } variant="contained" sx={{ width: '100%' }} onClick={saveRate} disabled={game.length > 0 && store.rating !== 0 ? false : true}>Сохранить</LoadingButton>
                    </div>
                    <Divider sx={{ mt: '1rem' }} />
                </Grid>

                <Grid item xs={12}>
                    <center>
                    <Button color="info" onClick={(e) => {
                            e.preventDefault();
                            window.open('https://vk.com/write-164323362?mvk_entrypoint=community_page', '_blank');
                            }} variant="contained" sx={{ width: '100%' }}
                        >
                        { 'Предложить свое упражнение' }</Button>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Button component={RouterLink} to={ Settings.menu[2].url } variant="contained" sx={{ width: '100%' }}>{ Settings.menu[2].button }</Button>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Button component={RouterLink} to={ Settings.menu[0].url } variant="contained" sx={{ width: '100%' }}>{ Settings.menu[0].button }</Button>
                    </center>
                </Grid>
                
            </Grid>
            )}
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={snackbar}
              onClose={handleSnackbar}
              message="Разминка сохранена!"
            />
        </Box>
    )
});
export default CalculatePage;

type Lessons = {
    id: number;
    lesson_id: number;
    name: string;
    value: number;
}

type Exercises = {
    id: number;
    type_id: number;
    name: string;
    open: boolean;
    lessons: Lessons[];
    // add tables
    enabled: boolean;
}

type Types = {
    id: number;
    name: string;
}

type Games = {
    id: number;
    name: string;
}

type AppState = {
    exercises: Exercises[];
    types: Types[];
    games: Games[];
    isLoadingTypes: boolean;
    isLoadingCalc: boolean;
}