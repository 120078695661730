import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import cyberten from '../assets/cyberten.png'
import tacticten from '../assets/tacticten.png'
import vk from '../assets/vk.png'
import { Grid, Tooltip } from '@mui/material';

export default function FooterComponent() {
    return (
        <Container fixed>
            <Box
                pt={{ xs: 5, sm: 3 }}
                pb={{ xs: 5, sm: 3 }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Tooltip title="Киберфитнес" disableInteractive>
                            <Link
                                href="https://cyberten.ru"
                                color="inherit"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src={cyberten}
                                    alt="Cyberten"
                                    loading="lazy"
                                    style={{ maxHeight: '100px', height: '100%', margin: '1px' }}
                                />
                            </Link>
                        </Tooltip>

                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex' }}>
                        <Typography variant='h5' style={{ margin: 'auto 0' }}>—&nbsp;&nbsp;&nbsp;киберфитнес</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Тактические доски для киберспорта" disableInteractive>
                            <Link
                                href="https://etactic.ru/"
                                color="inherit"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src={tacticten}
                                    alt="Tacticten"
                                    loading="lazy"
                                    style={{ maxHeight: '87px', height: '100%', margin: '1px' }}
                                />
                            </Link>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex' }}>
                        <Typography variant='h5' style={{ margin: 'auto 0' }}>—&nbsp;&nbsp;&nbsp;тактические доски для киберспорта</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Tooltip title="Группа ВКонтакте" disableInteractive>
                            <Link
                                href="https://vk.com/cyberlesgaft"
                                color="inherit"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src={vk}
                                    alt="vk"
                                    loading="lazy"
                                    style={{ maxHeight: '32px', height: '100%', margin: '1px' }}
                                />
                            </Link>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}