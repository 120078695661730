import { makeAutoObservable } from "mobx";

interface Field {
  id: number;
  lesson_id: number;
  type_id: number;
  value: number;
}

interface Exercises {
  id: number;
  checked: boolean;
}

class CalcState {

  fields: Field[] = [];
  exercises: Exercises[] = [];
  currentRating: number | null = 0;
  saveStatus: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setField(id: number, value: number) {   
    const index: number = this.fields.findIndex((field) => field.id === id);
    if (index !== -1) {
      this.fields[index].value = value;
    }
  }

  setRate(value: number | null) {
    this.currentRating = value;
  }
  saveRate() {
    this.saveStatus = !this.saveStatus;
  }

  changeOpenStatus(id: number) {                
    this.exercises.map((el) => {
      if (el.id === id) {
        el.checked = !el.checked;
      }
      return el;
    });    
  }

  get openStatus() {    
    return this.exercises;
  }

  get sum() {
    return this.fields.reduce((total, field) => total + field.value, 0);
  }
  get exercisesCount() {
    return this.fields.filter(obj => obj.value > 0).length;
  }

  get sumType1() {
    let filteredArr: any[] = this.fields.filter(obj => obj.type_id === 1);
    return filteredArr.reduce((total, field) => total + field.value, 0);
  }
  get exercisesType1() {
    return this.fields.filter(obj => obj.value > 0 && obj.type_id === 1).length;
  }
  get sumType2() {
    let filteredArr: any[] = this.fields.filter(obj => obj.type_id === 2);
    return filteredArr.reduce((total, field) => total + field.value, 0);
  }
  get exercisesType2() {
    return this.fields.filter(obj => obj.value > 0 && obj.type_id === 2).length;
  }

  get selectedExercises() {
    return this.fields.filter(obj => obj.value > 0);
  }

  get rating() {
    return this.currentRating;
  }

  get status() {
    return this.saveStatus;
  }
}

export default new CalcState();
