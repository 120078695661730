import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Settings from '../Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarRateIcon from '@mui/icons-material/StarRate';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AbcIcon from '@mui/icons-material/Abc';


import InfoTitleComponent from '../Components/InfoTitleComponent';
import axios from 'axios';
import { Accordion, AccordionDetails, AccordionSummary, ButtonGroup, IconButton, Pagination, Rating, Tooltip } from '@mui/material';

export default function RatingPage() {

    interface Exercise {
        id: number;
        lesson_id: number;
        type_id: number;
        type_name: string;
        lesson_name: string;
        exercise_name: string;
        value: number;
    };
    interface Warmup {
        id: number;
        game: string;
        data: Exercise[];
        rate: number;
        history: string;
        date: any;
    };

    const [state, setState] = useState<Warmup[]>([]);

    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [sort, setSort] = useState('rate');

    function handlePagination(event: React.ChangeEvent<unknown>, value: number) {
        setPage(value);
        location.href = 'https://warmten.ru/rating?page=' + value + '&sort=' + sort;
    }

    function handleSort(value: string) {
        setSort(value);
        location.href = 'https://warmten.ru/rating?page=' + page + '&sort=' + value;
    }

    useEffect(() => {
        const search = window.location.search;
        const obj: any = search.slice(search.indexOf('?') + 1).split('&').reduce((params, hash) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, {
                [key]: decodeURIComponent(val)
            })
        }, {});

        axios.get(`https://warmten.ru/api/v1/list/getRating.php?page=${obj.page ? obj.page : page}&sort=${obj.sort ? obj.sort : sort}`)
        .then(res => {
            let arr: Warmup[] = res.data.cards;
            arr.forEach((el: any) => {
                el.data = JSON.parse(el.data);
            });
            const rows = Math.ceil(+res.data.rows / 10);
            setMaxPage(rows);
            setPage(obj.page ? +obj.page : 1);
            setSort(obj.sort ? obj.sort : 'rate');
            setState(arr);
            
        })
        .catch(err => {
        })
        .finally(() => {
        });
    }, []);

    return (
        <Box 
            pt={{ xs: 5, sm: 3 }}
            pb={{ xs: 5, sm: 3 }}            
        >
            <Grid container spacing={3}>
              {/* <InfoTitleComponent/> */}

                <Grid item xs={12}>
                    <center>
                        <Typography variant='h6'>{ Settings.menu[2].name }</Typography>
                        <ButtonGroup
                            disableElevation
                            variant="contained"
                            aria-label="Кнопки для сортировки"
                        >
                            <Typography variant='subtitle1'>Сортировать:</Typography>
                            <Tooltip title="По рейтингу">
                                <IconButton aria-label="edit" size="small" onClick={() => {handleSort('rate')}}>
                                    <StarRateIcon color={ sort === 'rate' ? 'primary' : 'disabled' } fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="В алфавитном порядке">
                                <IconButton aria-label="delete" size="small" onClick={() => {handleSort('abc')}}>
                                    <AbcIcon color={ sort === 'abc' ? 'primary' : 'disabled' } fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="По дате">
                                <IconButton aria-label="delete" size="small" onClick={() => {handleSort('date')}}>
                                    <CalendarMonthIcon color={ sort === 'date' ? 'primary' : 'disabled' } fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="По длительности">
                                <IconButton aria-label="delete" size="small" onClick={() => {handleSort('time')}}>
                                    <AccessTimeFilledIcon color={ sort === 'time' ? 'primary' : 'disabled' } fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </center>
                </Grid>
                
                {
                    state.length > 0 ?
                        state.map((el, i) => {
                            let sum: number = 0;

                            for (let ex of el.data) {
                                sum += ex.value;
                            }
                            return (
                            <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel${i}bh-content`}
                                  id={`panel${i}bh-header`}
                                >
                                    <Grid item xs={6}>
                                  <Typography component="legend" sx={{ color: 'text.disabled' }}>{el.date}</Typography>
                                <Typography variant='h6'>{ el.game + " (" + sum + " сек.)"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography component="legend" sx={{ color: 'text.disabled' }}>{el.rate} из 10</Typography>
                                  <Rating
                                    name="size-large"
                                    size="large"
                                    max={10}
                                    value={ el.rate }
                                    readOnly
                                    />
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        el.data.map(elem => (
                                            <Grid container spacing={3}>
                                <Grid item xs={10}>
                                        <Typography variant='subtitle1'>{ elem.type_name + ' > ' + elem.lesson_name + ' > ' + elem.exercise_name } </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                        <Typography variant='subtitle1'>{ elem.value + ' .сек' }</Typography>
                                </Grid>
                                </Grid>
                                        ))
                                    }
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                            )
                            }) : (
                            <Grid item xs={12}>
                            <center>
                                <Typography variant='h6'>{ 'Отсутствует рейтинг разминок!' }</Typography>
                            </center>
                            </Grid>
                        )                   
                }

                <Grid item xs={12}>
                    <center>
                        <Pagination style={{ width: 'fit-content' }} count={maxPage} page={page} onChange={handlePagination} />
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Button component={RouterLink} to={ Settings.menu[1].url } variant="contained" sx={{ width: '100%' }}>{ Settings.menu[1].button }</Button>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Button component={RouterLink} to={ Settings.menu[0].url } variant="contained" sx={{ width: '100%' }}>{ Settings.menu[0].button }</Button>
                    </center>
                </Grid>
                
            </Grid>
        </Box>
    )
}