import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Settings from '../Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';


import axios from 'axios';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, List, ListItem, ListItemText, Pagination, Paper, Rating, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export default function AdminPanel() {

    const [password, setPassword] = useState("");
    const [showPage, setShowPage] = useState(false);

    const handlePasswordInput = () => {
        const input = prompt("Введите пароль:");
        if (input === "warmten") {
            setPassword(input);
            localStorage.setItem('authorized', 'true');
            setShowPage(true);
        } else {
            alert("Неверный пароль. Попробуйте снова.");
        }
    };

    const [state, setState] = useState<AppState>({
        lessons: [],
        types: [],
        games: [],
        rating: [],
        loaded: false,
    });

    const [changeType, setChangeType] = useState('1');
    const handleChangeType = (newValue: string) => {
        setChangeType(newValue);
    };

    let [open, setOpen] = useState<openStatus[]>([]);

    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);

    function handlePagination(event: React.ChangeEvent<unknown>, value: number) {
        setPage(value);
        location.href = 'https://warmten.ru/admin-panel?page=' + value + '&sort=rate';
    }


    const [deletemodal, setModalDelete] = useState({
        opened: false,
        type: '',
        id: 0
    });

    const handleClickOpen = (type: string, id: number) => {
        let obj = {
            opened: true,
            type: type,
            id: id
        };
        setModalDelete(obj);
    };

    const handleClose = (deleted: boolean = false) => {
        if (deleted) {
            axios.post('https://warmten.ru/api/v1/list/deleteItem.php', deletemodal)
                .then(function (response) {
                    let obj = {
                        opened: false,
                        type: '',
                        id: 0
                    };
                    setModalDelete(obj);
                    window.location.reload();
                })
                .catch(function (error) {
                });
        } else {
            let obj = {
                opened: false,
                type: '',
                id: 0
            };
            setModalDelete(obj);
        }
    };

    const [editmodal, setModalEdit] = useState<modalState>({
        opened: false,
        type: '',
        value: '',
        id: 0
    });

    const handleClickOpenEdit = (type: string, id: number, value: string) => {
        let obj = {
            opened: true,
            type: type,
            value: value,
            id: id
        };
        setModalEdit(obj);
    };

    const handleCloseEdit = (edited: boolean = false) => {
        if (edited) {
            axios.post('https://warmten.ru/api/v1/list/editItem.php', editmodal)
                .then(function (response) {
                    let obj = {
                        opened: false,
                        type: '',
                        value: '',
                        id: 0
                    };
                    setModalEdit(obj);
                    // window.location.reload();
                })
                .catch(function (error) {
                });

        } else {
            let obj = {
                opened: false,
                type: '',
                value: '',
                id: 0
            };
            setModalEdit(obj);
        }
    };

    const [addmodal, setModalAdd] = useState<modalState>({
        opened: false,
        type: '',
        value: '',
        id: 0
    });

    const handleClickOpenAdd = (type: string, id: number, value: string) => {
        let obj = {
            opened: true,
            type: type,
            value: value,
            id: id
        };
        setModalAdd(obj);
    };

    const handleCloseAdd = (added: boolean = false) => {
        if (added) {
            axios.post('https://warmten.ru/api/v1/list/addItem.php', addmodal)
                .then(function (response) {
                    let obj = {
                        opened: false,
                        type: '',
                        value: '',
                        id: 0
                    };
                    setModalAdd(obj);
                    // window.location.reload();
                })
                .catch(function (error) {
                });

        } else {
            let obj = {
                opened: false,
                type: '',
                value: '',
                id: 0
            };
            setModalAdd(obj);
        }
    };

    const handlerAdd = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = event.target.value;
        setModalAdd({ ...addmodal, value: value });
    }

    const handlerEdit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = event.target.value;

        setModalEdit({ ...editmodal, value: value });
        console.log(editmodal);
    }

    useEffect(() => {
        const search = window.location.search;
        const obj: any = search.slice(search.indexOf('?') + 1).split('&').reduce((params, hash) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, {
                [key]: decodeURIComponent(val)
            })
        }, {});
        axios.get(`https://warmten.ru/api/v1/list/getTypes.php`)
            .then(res => {
                setState((oldState) => ({ ...oldState, types: res.data }));
                axios.get(`https://warmten.ru/api/v1/list/getLessons.php`)
                    .then(res => {
                        let obj: Lessons[] = res.data;
                        axios.get(`https://warmten.ru/api/v1/list/getExercises.php`)
                            .then(resp => {
                                obj.forEach(firstObj => {
                                    const matchingObjs: Exercises[] = resp.data.filter((secondObj: Exercises) => secondObj.lesson_id === firstObj.id);
                                    firstObj.exercises = matchingObjs;
                                    setOpen((old) => { return [...old, { id: firstObj.id, open: false }] });
                                });

                                setState((oldState) => ({ ...oldState, lessons: obj }));
                            })
                            .catch(err => {
                            })
                            .finally(() => {
                            });
                    })
                    .catch(err => {
                    })
                    .finally(() => {
                    });
            })
            .catch(err => {
            })
            .finally(() => {
                setState((oldState) => ({ ...oldState, loaded: true }));
            });

        axios.get(`https://warmten.ru/api/v1/list/getGames.php`)
            .then(res => {
                setState((oldState) => ({ ...oldState, games: res.data }));
            })
            .catch(err => {
            })
            .finally(() => {
            });


        axios.get(`https://warmten.ru/api/v1/list/getRating.php?page=${obj.page ? obj.page : page}&sort=rate`)
            .then(res => {
                let arr = res.data.cards;
                arr.forEach((el: any) => {
                    el.data = JSON.parse(el.data);
                });
                const rows = Math.ceil(+res.data.rows / 10);
                setMaxPage(rows);
                setPage(obj.page ? +obj.page : 1);
                setState((oldState) => ({ ...oldState, rating: arr }));
            })
            .catch(err => {
            })
            .finally(() => {
            });
    }, []);

    return (localStorage.getItem('authorized') && localStorage.getItem('authorized') === 'true') || showPage ? (
        <Box
            pt={{ xs: 5, sm: 3 }}
            pb={{ xs: 5, sm: 3 }}
        >
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <center>
                        <Typography variant='h4'>{Settings.menu[5].name}</Typography>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Typography variant='h5'>Управление категориями</Typography>
                    </center>
                </Grid>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <TabContext value={changeType}>

                            <TabList
                                scrollButtons
                                allowScrollButtonsMobile
                                onChange={
                                    (event, newValue) => {
                                        handleChangeType(newValue)
                                    }
                                }
                                aria-label="lab API tabs example"
                                centered
                            >
                                {
                                    state.types.map(
                                        (type) => {
                                            return (
                                                <Tab key={`type-t-${type.id}`} label={type.name} value={`${type.id}`} />
                                            )
                                        }
                                    )
                                }
                            </TabList>

                            {
                                state.types.map(
                                    (type) => {
                                        return (
                                            <TabPanel key={`type-v-${type.id}`} value={`${type.id}`} sx={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ width: '100%' }} aria-label="collapsible table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left">
                                                                    <Typography variant='body1'>{`${type.name} упражнения`}</Typography>
                                                                </TableCell>
                                                                <TableCell />
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                                <TableCell align="left">
                                                                    <Button variant="text" sx={{ color: 'white' }} startIcon={<AddIcon />} onClick={() => handleClickOpenAdd('lesson', type.id, '')}>
                                                                        Добавить
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                            {
                                                                state.lessons
                                                                    .filter((lesson) => lesson.type_id === type.id)
                                                                    .map((lesson) => {

                                                                        if (lesson.exercises.length > 0) {
                                                                            let index: number = open.findIndex((el) => el.id === lesson.id);
                                                                            return (
                                                                                <>
                                                                                    <TableRow key={`row-t-${lesson.id}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                                                        <TableCell component="th" scope="row">
                                                                                            <Typography sx={{ color: Settings.Colors.Button.Primary }}>
                                                                                                <FormControlLabel control={
                                                                                                    <Checkbox
                                                                                                        color="primary"
                                                                                                        checked={open[index].open}
                                                                                                        onClick={() => {
                                                                                                            let copy: openStatus[] = Object.assign([], open);
                                                                                                            copy[index].open = !copy[index].open;
                                                                                                            setOpen(copy);
                                                                                                        }}
                                                                                                    />
                                                                                                } label={lesson.name + ` (${lesson.exercises.length})`} />

                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell align="right">
                                                                                            <Tooltip title="Переименовать">
                                                                                                <IconButton aria-label="edit" onClick={() => handleClickOpenEdit('lesson', lesson.id, lesson.name)}>
                                                                                                    <EditIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                            <Tooltip title="Удалить">
                                                                                                <IconButton aria-label="delete" onClick={() => handleClickOpen('lesson', lesson.id)}>
                                                                                                    <DeleteIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                                            <Collapse in={open[index].open} timeout="auto" unmountOnExit>
                                                                                                <Box sx={{ margin: 0, width: '100%' }}>
                                                                                                    <Table size="small" aria-label="purchases">
                                                                                                        <TableHead>
                                                                                                            <TableRow>
                                                                                                                <TableCell>
                                                                                                                    <Typography sx={{ color: Settings.Colors.Button.Primary }}>
                                                                                                                        Тип упражнения
                                                                                                                    </Typography>
                                                                                                                </TableCell>
                                                                                                                <TableCell align="right">
                                                                                                                    <Typography sx={{ color: Settings.Colors.Button.Primary }}>
                                                                                                                        Действия
                                                                                                                    </Typography>
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                                                                                        </TableHead>
                                                                                                        <TableBody>
                                                                                                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                                                                                <TableCell align="left">
                                                                                                                    <Button variant="text" sx={{ color: 'white' }} startIcon={<AddIcon />} onClick={() => handleClickOpenAdd('exercise', lesson.id, '')}>
                                                                                                                        Добавить
                                                                                                                    </Button>
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                                                                                            {
                                                                                                                lesson.exercises.map((exercise) => {
                                                                                                                    return (
                                                                                                                        <TableRow key={`${exercise.id}-${exercise.name}`}>
                                                                                                                            <TableCell component="th" scope="row">
                                                                                                                                {exercise.name}
                                                                                                                            </TableCell>

                                                                                                                            <TableCell align="right">
                                                                                                                                <Tooltip title="Переименовать">
                                                                                                                                    <IconButton aria-label="edit" size="small" onClick={() => handleClickOpenEdit('exercise', exercise.id, exercise.name)}>
                                                                                                                                        <EditIcon fontSize="small" />
                                                                                                                                    </IconButton>
                                                                                                                                </Tooltip>
                                                                                                                                <Tooltip title="Удалить">
                                                                                                                                    <IconButton aria-label="delete" size="small" onClick={() => handleClickOpen('exercise', exercise.id)}>
                                                                                                                                        <DeleteIcon fontSize="small" />
                                                                                                                                    </IconButton>
                                                                                                                                </Tooltip>
                                                                                                                            </TableCell>
                                                                                                                        </TableRow>
                                                                                                                    );
                                                                                                                })
                                                                                                            }
                                                                                                        </TableBody>
                                                                                                    </Table>
                                                                                                </Box>
                                                                                            </Collapse>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <></>
                                                                            )
                                                                        }
                                                                    })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </TabPanel>
                                        )
                                    }
                                )
                            }
                        </TabContext>
                    </Grid>

                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Typography variant='h5'>Управление играми для оценки</Typography>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <List dense={false}>
                            <ListItem>
                                <Button variant="text" sx={{ color: 'white' }} startIcon={<AddIcon />} onClick={() => handleClickOpenAdd('game', 0, '')}>
                                    Добавить
                                </Button>
                            </ListItem>
                            {state.games.map(game => (
                                <>
                                    <ListItem
                                        key={`game${game.id}`}
                                        secondaryAction={
                                            <>
                                                <Tooltip title="Переименовать">
                                                    <IconButton aria-label="edit" size="small" onClick={() => handleClickOpenEdit('game', game.id, game.name)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Удалить">
                                                    <IconButton aria-label="delete" size="small" onClick={() => handleClickOpen('game', game.id)}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        <ListItemText
                                            primary={game.name}
                                        />
                                    </ListItem>
                                    <Divider />
                                </>
                            ))}
                        </List>                    
                    </center>
                </Grid>




                <Grid item xs={12}>
                    <center>
                        <Typography variant='h5'>Управление рейтингом</Typography>
                    </center>
                </Grid>

                {
                    state.rating.length > 0 ?
                        state.rating.map((el, i) => {
                            let sum: number = 0;

                            for (let ex of el.data) {
                                sum += ex.value;
                            }
                            return (
                                <Grid item xs={12} key={`item${el.id}`}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${i}bh-content`}
                                            id={`panel${i}bh-header`}
                                        >
                                            <Grid item xs={6}>
                                                <Typography component="legend" sx={{ color: 'text.disabled' }}>{el.date}</Typography>
                                                <Typography variant='h6'>{el.game + " (" + sum + " сек.)"}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography component="legend" sx={{ color: 'text.disabled' }}>{el.rate} из 10</Typography>
                                                <Rating
                                                    name="size-large"
                                                    size="large"
                                                    max={10}
                                                    value={el.rate}
                                                    readOnly
                                                />
                                                <Tooltip title="Изменить рейтинг">
                                                    <IconButton aria-label="edit" onClick={() => handleClickOpenEdit('rating', el.id, el.rate.toString())}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Удалить">
                                                    <IconButton aria-label="delete" onClick={() => handleClickOpen('rating', el.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {
                                                el.data.map(elem => (
                                                    <Grid container spacing={3} key={`ex${elem.id}`}>
                                                        <Grid item xs={10}>
                                                            <Typography variant='subtitle1'>{elem.type_name + ' > ' + elem.lesson_name + ' > ' + elem.exercise_name} </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography variant='subtitle1'>{elem.value + ' .сек'}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )
                        }) : (
                            <Grid item xs={12}>
                                <center>
                                    <Typography variant='h6'>{'Отсутствует рейтинг разминок!'}</Typography>
                                </center>
                            </Grid>
                        )
                }

                <Grid item xs={12}>
                    <center>
                        <Pagination style={{ width: 'fit-content' }} count={maxPage} page={page} onChange={handlePagination} />
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Button component={RouterLink} to={Settings.menu[1].url} variant="contained" sx={{ width: '100%' }}>{Settings.menu[1].button}</Button>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Button component={RouterLink} to={Settings.menu[0].url} variant="contained" sx={{ width: '100%' }}>{Settings.menu[0].button}</Button>
                    </center>
                </Grid>

            </Grid>
            <>
                <Dialog
                    key={`del`}
                    open={deletemodal.opened}
                    onClose={() => handleClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Вы уверены что хотите удалить элемент?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Подтвердите выбранное действие.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose(false)}>Отмена</Button>
                        <Button onClick={() => handleClose(true)} autoFocus>
                            Удалить
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog key={`ed`} open={editmodal.opened} onClose={() => handleCloseEdit(false)}>
                    <DialogTitle>Введите новое значение</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="edit"
                            label="Значение"
                            type="text"
                            value={editmodal.value}
                            fullWidth
                            onChange={(event) => handlerEdit(event)}
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleCloseEdit(false)}>Отмена</Button>
                        <Button onClick={() => handleCloseEdit(true)}>Применить</Button>
                    </DialogActions>
                </Dialog>
                <Dialog key={`add`} open={addmodal.opened} onClose={() => handleCloseAdd(false)}>
                    <DialogTitle>Добавление нового элемента</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        <FormControl sx={{ width: '25ch' }}>
                            <TextField
                                margin="dense"
                                id="add"
                                label="Значение"
                                type="text"
                                value={addmodal.value}
                                fullWidth
                                onChange={(event) => handlerAdd(event)}
                                variant="standard"
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleCloseAdd(false)}>Отмена</Button>
                        <Button onClick={() => handleCloseAdd(true)}>Применить</Button>
                    </DialogActions>
                </Dialog>
            </>
        </Box>
    ) : (
        <Box
            pt={{ xs: 5, sm: 3 }}
            pb={{ xs: 5, sm: 3 }}
        >
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <center>
                        <Typography variant='h6'>Авторизуйтесь</Typography>
                        <Button variant="contained" onClick={handlePasswordInput}>Войти</Button>
                    </center>
                </Grid>
            </Grid>
        </Box>
    )
}

type Exercises = {
    id: number;
    lesson_id: number;
    name: string;
}

type Lessons = {
    id: number;
    type_id: number;
    name: string;
    exercises: Exercises[];
}

type Types = {
    id: number;
    name: string;
}

type Games = {
    id: number;
    name: string;
}

type warmupEx = {
    id: number;
    lesson_id: number;
    type_id: number;
    type_name: string;
    lesson_name: string;
    exercise_name: string;
    value: number;
};
type Warmup = {
    id: number;
    game: string;
    data: warmupEx[];
    rate: number;
    history: string;
    date: any;
};

type openStatus = {
    id: number;
    open: boolean;
};

type modalState = {
    opened: boolean;
    type: string;
    value: string;
    id: number;
};

type AppState = {
    lessons: Lessons[];
    types: Types[];
    games: Games[];
    rating: Warmup[];
    loaded: boolean;
}