import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import HeaderComponent from './Components/HeaderComponent';
import FooterComponent from './Components/FooterComponent';

import MainPage from './Pages/MainPage';
import CalculatePage from './Pages/CalculatePage';
import RatingPage from './Pages/RatingPage';
import Container from '@mui/material/Container';
import Error404 from './Pages/Errors/Error404';
import Settings from './Settings';
import ContactsPage from './Pages/ContactsPage';
import InformationPage from './Pages/InformationPage';
import AdminPanel from './Pages/AdminPanel';

const theme = createTheme({
  palette: {
    primary: {
      main: Settings.Colors.Button.Primary,
    },
    mode: 'dark',
    text: {
      primary: Settings.Colors.Text.Primary,
      secondary: Settings.Colors.Text.Secondary,
    },
    background: {
      
    }
  },
  typography: {
    button: {
      fontSize: 18
    }
  }
});

export default class App extends React.Component {
  render() {
    return(
      <>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <HeaderComponent />
          <Container fixed>
            <Routes>
              <Route path={ Settings.menu[0].url } element={ <MainPage/> }/>
              <Route path={ Settings.menu[1].url } element={ <CalculatePage/> }/>
              <Route path={ Settings.menu[2].url } element={ <RatingPage/> }/>
              <Route path={ Settings.menu[3].url } element={ <InformationPage/> }/>
              <Route path={ Settings.menu[4].url } element={ <ContactsPage/> }/>
              <Route path={ Settings.menu[5].url } element={ <AdminPanel/> }/>
              <Route path="*" element={ <Error404/> }/>
            </Routes>
          </Container>
          <FooterComponent />
        </ThemeProvider>
      </>
    );
  }
}