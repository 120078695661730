export default class Settings {
    static menu = [
        Settings.getMenu('КАЛЬКУЛЯТОР РАЗМИНКИ ДЛЯ КИБЕРСПОРТСМЕНОВ', 'Вернуться на главную', '/'),
        Settings.getMenu('РАЗМИНКА ДЛЯ КИБЕРСПОРТСМЕНОВ', 'Сделать свою разминку', '/calculate'),
        Settings.getMenu('РЕЙТИНГ КИБЕРСПОРТИВНЫХ РАЗМИНОК', 'Рейтинг разминок', '/rating'),
        Settings.getMenu('ИНФОРМАЦИЯ', 'Информация', '/information'),
        Settings.getMenu('НАШИ КОНТАКТЫ', 'Контакты', '/contacts'),
        Settings.getMenu('АДМИН-ПАНЕЛЬ', 'Админ-панель', '/admin-panel'),
    ];

    static tables = [
        Settings.getTabels('Цифровые упражнения', 'Баллы', 'Длительность'),
        Settings.getTabels('Физические упражнения', 'Баллы', 'Длительность'),
    ];

    static rows = [
        Settings.getExercises('Тренировка Aim', 0),
        Settings.getExercises('Добавления крипов', 0),
        Settings.getExercises('Игра в OSU', 0),
    
        Settings.getExercises('Приседания', 1),
        Settings.getExercises('Отжимания', 1),
        Settings.getExercises('Бросок в стену теннисного меча с чередованием рук', 1),
    ];

    static API = class {
        static Url = "https://warmten.ru/";
        static Version = "v1";
    }

    static Colors = class {
        static Text = class {
            static Primary = `#ffffff`;
            static Secondary = `#ffffff`;           
        }
        static Button = class {
            static Primary = `#4079c2`;
        }
    }

    private static getTabels( name: string, points: string, duration: string ) {
        return { name, points, duration };
    }

    private static getExercises( name: string, type: number ) {
        return { name, type };
    }

    private static getMenu( name: string, button: string, url: string) {
        return { name, button, url}
    }
}