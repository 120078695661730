import { Link as ToLink } from 'react-router-dom';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Settings from '../Settings';

import logo from "../assets/logo.png";
import lesgaft from "../assets/lesgaft.png";

export default function HeaderComponent() {
    return (
        <AppBar
            position='sticky'
        >
            <Container fixed>
                <Toolbar>
                    <div style={{ width: '10%', textAlign: 'right' }}>
                        <img 
                            src={ lesgaft } 
                            style={{
                                height: '40%',
                                width: '50%',                                
                            }}
                            title='Университет Лесгафта'
                        />
                    </div>
                    <ToLink to="/" style={{ textDecoration: 'none', color: Settings.Colors.Text.Primary, width: '80%', display: 'flex' }}>
                        <img 
                            src={ logo } 
                            style={{
                                height: '40%',
                                width: '40%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        />
                        {/* <Typography variant='h6'>WarmTen.RU</Typography> */}
                    </ToLink>
                    <div style={{ width: '10%' }}>                    
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    );
}