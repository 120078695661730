import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from 'react';

export default function InfoLoadComponent() {
    return (
        <Grid item xs={12}>
            <br/>
            <Alert severity="info" color="info">
                <AlertTitle><Typography variant='h6'>Идет загрузка!</Typography></AlertTitle>
                <Typography variant='body1'>Запрашиваем у сервера информацию по упражнениям...</Typography>
            </Alert>
        </Grid>
    )
}