import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Settings from '../Settings';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';

export default function InformationPage() {
    return (
        <Box 
            pt={{ xs: 5, sm: 10 }}
            pb={{ xs: 5, sm: 5 }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <center>
                        <Typography variant='h5'>{ Settings.menu[3].name }</Typography>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <Alert severity="info" color="info">
                        <AlertTitle><Typography variant='h6'>Зачем разминка киберспортсменам?</Typography></AlertTitle>
                        <Typography variant='body2'>Перед любой тренировкой необходимо делать разминку, так как это позволяет плавно подготовить организм к нагрузке, снизить вероятность травм и улучшить производительность организма.</Typography>
                        <br/>
                        <Typography variant='body2'>Кому-то разминка может показаться скучной и отнимающей лишнее время, особенно для киберспортсменов.</Typography>
                        <br/>
                        <Typography variant='body2'><strong>Киберспортсмены</strong> – «когнитивные спортсмены», поэтому мы особенно нуждаемся в разминке для мозга, наш организм достаточно инертный и ему требуется некоторое время для того, чтобы достигнуть готовности к тренировочной или соревновательной деятельности.</Typography>
                        <br/>
                    </Alert>
                </Grid>

                <Grid item xs={12}>
                    <Alert severity="info" color="info">
                        <AlertTitle><Typography variant='h6'>Мало кто знает, НО ...</Typography></AlertTitle>
                        <Typography variant='body1'>{ 'Результативность в «цифровых упражнениях» можно повысить с помощью простых физических упражнений! Проведите простой эксперимент сами!'}</Typography>
                        <br/>
                        <Typography variant='body2'>{ 'Например, выполните тест «скорость реакции» несколько раз на портале ' }
                        <Link 
                            href="https://cyberten.ru/"
                            color="inherit"
                            target="_blank"
                            rel="noopener"
                        >CyberTen.RU</Link> { 'затем выполните 2-3 простых физических упражнения (для крупных групп мышц или комплексные упражнения) и повторите тест снова и вы увидите наглядный эффект от выполнения физических упражнений.'} </Typography>
                    </Alert>
                </Grid>

                <Grid item xs={12}>
                        <Button component={RouterLink} to={ Settings.menu[0].url } variant="contained" sx={{ width: '100%' }}>{ Settings.menu[0].button }</Button>
                </Grid>
            </Grid>
        </Box>
    )
}