import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Settings from '../Settings';
import { Alert, AlertTitle } from '@mui/material';

export default function ContactsPage() {
    return (
        <Box 
            pt={{ xs: 5, sm: 10 }}
            pb={{ xs: 5, sm: 5 }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <center>
                        <Typography variant='h5'>{ Settings.menu[4].name }</Typography>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <Alert icon={false} severity="info" color="info" variant="outlined">
                        <AlertTitle><Typography variant='h6'>Как связаться с разработчиком сайта?</Typography></AlertTitle>
                        <Typography variant='body2'>На данный момент, мы можете связаться с нами напрямую через нашу группу ВКонтакте!</Typography>
                    </Alert>
                </Grid>

                <Grid item xs>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            window.open('https://vk.com/write-164323362?mvk_entrypoint=community_page', '_blank');
                            }} variant="contained" sx={{ width: '100%' }}
                        >
                        { 'Обратная связь ВКонтакте' }</Button>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        <Button component={RouterLink} to={ Settings.menu[0].url } variant="contained" sx={{ width: '100%' }}>{ Settings.menu[0].button }</Button>
                    </center>
                </Grid>
            </Grid>
        </Box>
    )
}